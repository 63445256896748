<template>
  <div>
    <van-sticky :offset-top="50">
      <van-button type="primary" style="position: absolute" @click="onShowItemPopup()">选择分类</van-button>
    </van-sticky>
    <div class="address-popup-load">
      <van-loading v-if="showLoading && init" type="spinner" color="#1989fa"/>
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="!init">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in goods" :key="item.id" :name="item.id">
          <van-card style="padding: 8px 1px;"
                    :title="item.storeName"
                    :desc="item.storeInfo"
                    :price="formatPrice(item.price)"
                    :thumb="item.image"
                    :origin-price="formatPrice(item.otPrice)"
                    @click-thumb="showImages(item.productId)"
          >
            <template #footer>
              <van-button size="mini" type="info" @click="onShowSkuItemPopup(item.newWholesaleAttr)">更多规格</van-button>
            </template>
          </van-card>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="skuItemsPopupShow">
      <div v-for="item in skuItems" :key="item.id" :name="item.id" style="width: 300px">
        <van-card
            :price="formatPrice(item.price)"
            :title="item.sku"
            :thumb="item.image"
        >
        </van-card>
      </div>
    </van-popup>

    <van-popup v-model="showItemPopup" position="left">
      <van-sidebar v-model="activeKey">
        <div v-for="item in items" :key="item.id" :name="item.id">
          <van-sidebar-item :title="item.cateName" @click="showAddAlert(item.id)"/>
        </div>
      </van-sidebar>
    </van-popup>

    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>第{{ index }}页</template>
    </van-image-preview>
  </div>
</template>

<script>

import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Popup,
  Image,
  ImagePreview,
  Tag,
  Button,
  Sidebar,
  SidebarItem,
  Notify,
  Col,
  Row,
  Loading,
  List,
  PullRefresh,
  Sticky,
  Dialog
} from 'vant';

import * as productService from "@/api/serverData/getServerData";
import {wechatEvevt} from "@/libs/wechat";

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Notify.name]: Notify,
    [Col.name]: Col,
    [Row.name]: Row,
    [Loading.name]: Loading,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Sticky.name]: Sticky,
    [Dialog.name]: Dialog
  },

  data() {
    return {
      showItemPopup: false,
      empty: false,
      init: true,
      activeKey: 0,
      goods: [],
      items: [],
      show: false,
      showLoading: true,
      index: 0,
      images: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
      ],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      itemId: null,
      skuItemsPopupShow: false,
      skuItems: []
    };
  },
  created() {
    if (this.$route.params && this.$route.params.userUuid) {
      productService.getWholesaleItems(this.$route.params.userUuid).then(res => {
        if (res.status === 200) {
          this.init = false;
          this.items = res.data;
          this.showLoading = false;
          if (this.items.length >= 1) {
            this.showLoading = true;
            this.itemId = this.items[0].id;
            this.onRefresh();
          } else {
            this.empty = true;
            Dialog.alert({
              message: "管理员正在整理您的商品价格，请耐心等待～",
              theme: 'round-button',
            }).then(() => {
              wechatEvevt('closeWindow')
            });
          }
        } else {
          this.showLoading = false;
          this.finished = true;
          Dialog.alert({
            message: res.msg,
            theme: 'round-button',
          }).then(() => {
            wechatEvevt('closeWindow')
          });
        }
      }).catch(error => {
      });
    }
  },
  computed: {},
  methods: {
    onShowItemPopup() {
      this.showItemPopup = true
    },
    showAddAlert(itemId) {
      this.showItemPopup = false
      this.goods = [];
      this.itemId = itemId;
      this.resetData();
      this.onRefresh();
    },
    formatPrice(price) {
      return (price * 1).toFixed(2);
    },
    showImages(productId) {
      this.show = !this.show;
      console.log(productId)
      this.goods.forEach(item => {
        if (item.productId === productId) {
          console.log(item.sliderImage)
          this.images = item.sliderImage.split(',');
        }
      })
    },
    onChange(index) {
      this.index = index;
    },
    onLoad() {
      if (this.refreshing) {
        this.goods = [];
        this.refreshing = false;
      }
      if (this.itemId !== null && this.$route.params && this.$route.params.userUuid) {
        this.showLoading = true;
        let param = 'sid=' + this.itemId + '&page=' + this.pages.currPage + '&limit=10';
        let that = this;
        productService.getWholesaleProducts(this.$route.params.userUuid, param).then(res => {
          if (res.status === 200) {
            that.goods = that.goods.concat(res.data.records)
            this.showLoading = false;
            this.loading = false;
            that.pages.currPage++;
            that.pages.pageCount = res.data.pages;
            if (that.pages.currPage > that.pages.pageCount) {
              that.finished = true;
            }
          }
        });
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onShowSkuItemPopup(items) {
      this.skuItems = items
      this.skuItemsPopupShow = true
    },
  }
};
</script>

<style lang="less">
.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
